import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {Schulweg, SchülerGeneratedClient, Student as APIStudent, StudentToClassAssignments} from "src/app/API/generated/clients";
import * as _ from "lodash";
import * as moment from "moment";
import {AuthService} from "src/app/services/auth/auth.service";
import {Student, StudentProvider, StudentsAndPerimeter} from "./student-provider";
import {Schoolclass} from "../../schoolclass/providers/schoolclass-provider";

@Injectable({
  providedIn: "root"
})
export class ApiStudentProvider implements StudentProvider {
  constructor(private apiClient: SchülerGeneratedClient, private authService: AuthService) {}
  getSchoolroute(studentId: number, perimeterId: string): Observable<Schulweg[]> {
    return this.apiClient.schüler_GetSchulweg(studentId, perimeterId, this.authService.realmId);
  }

  getStudents(filterId: number, foreignLanguage: boolean, klasse: boolean, classPlan: boolean, level: string,
              gender: string, schoolBuildingType: string): Observable<Array<Student>> {
    // TODO must add right params
    return this.apiClient.schüler_Get(this.authService.realmId, filterId, foreignLanguage, klasse, classPlan, level, gender, schoolBuildingType).pipe(
      map(students => {
        return students.map(s => this.mapApiMessage(s));
      })
    );
  }

  setSchoolclassAssignments(schoolclass: Schoolclass, studentIds: Array<number>, isClassPlan: boolean): Observable<void> {
    const newAssignments: StudentToClassAssignments = {
      classId: schoolclass ? schoolclass.id : undefined,
      studentIds: studentIds,
      isPlanklasse: isClassPlan
    };

    return this.apiClient.schüler_SetSchoolclassAssignments(newAssignments, this.authService.realmId);
  }

  removePlanSchoolclassAssignment(studentId: number): Observable<void> {
    return this.apiClient.schüler_DeletePlanclassAssignments(studentId, this.authService.realmId);
  }
  setPerimeters(newAssignments: Array<StudentsAndPerimeter>): Observable<void> {
    return this.apiClient.schüler_SetPerimeter(newAssignments, this.authService.realmId);
  }

  private mapApiMessage(s: APIStudent): Student {
    return new Student(
      s.id,
      s.name,
      s.firstname,
      s.birthday,
      s.gender,
      s.foreignLanguage,
      s.address,
      s.zip,
      s.town,
      s.coordinateE, // x
      s.coordinateN, // y
      s.gisRemark,
      undefined,
      s.schoolclassId,
      undefined,
      s.planclassId,
      undefined
    );
  }
}
